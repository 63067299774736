<template>
  <div v-if="areGamesVisible" class="columns">
    <div class="column is-12">
      <div class="game-selector">
        <div>
          <BButton type="is-light" rounded icon-left="arrow-left-thick" @click.prevent="toBack">VOLVER</BButton>
        </div>
        <template v-if="areGamesVisible">
          <div class="game-selector__text">Elige Partido:</div>
          <div class="game-selector__select">
            <BSelect v-model="selectedGame" placeholder="Seleccione" type="is-info">
              <option v-for="game in availableGames" :key="game.game_id" :value="game">
                {{ game.name }}
              </option>
            </BSelect>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div v-else class="columns" style="padding-top: 1rem;">
    <div class="column is-2">
      <BButton v-if="isInnerComponent" type="is-light" rounded icon-right="window-close" @click.prevent="$emit('close')"
        >Cerrar</BButton
      >
      <BButton v-else type="is-light" rounded icon-left="arrow-left-thick" @click.prevent="toBack">VOLVER</BButton>
    </div>

    <div class="column is-10">
      <template v-if="menuOptionActive == 'stats'">
        <div class="is-inline-flex is-pulled-left">
          <div class="column is-12 margin-column-select">
            <div class="is-inline-flex is-pulled-left ">
              <div class="column is-11 no-padding">
                <span>
                  TIPO DE APUESTA
                </span>
              </div>
              <div class="column is-1 no-padding">
                <div class="info-buttons" @click="onClickTipoDeApuestaVerInformacion">
                  <BIcon
                    class="tips__top-bets__text__icon-info"
                    icon="information-outline"
                    :size="this.displayWidth < 525 ? 'is-small' : 'is-normal'"
                  />
                </div>
              </div>
            </div>
            <BSelect
              placeholder="Seleccione"
              type="is-info"
              :value="selectedAnalysis"
              @input="updateSelectedAnalysis"
              @change.native="onChangeTipoApuesta"
            >
              <option v-for="(option, index) in analysisTypeOptions" :key="index" :value="option">
                {{ option.name }}
              </option>
            </BSelect>
          </div>
        </div>
        <div class="is-pulled-left">
          <div class="tips__videos__buttons">
            <div class="button button-video is-rounded golstats-button" @click="showVideo">
              <span>GolStats</span>
              <span class="icon">
                <i class="mdi mdi-play-circle-outline"></i>
              </span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="is-inline-flex is-pulled-left div-flag-menu-hidden"></div>
      </template>
      <div class="buttons is-pulled-right margin-flag-live">
        <button
          v-if="showLive"
          class="button is-rounded is-live-inactive"
          :class="{ 'is-live-active': showLiveData }"
          @click="switchShowLiveData"
        >
          <BIcon pack="mdi" icon="circle" size="is-small" type="is-green"> </BIcon>
          <span>LIVE</span>
        </button>
        <template>
          <BButton type="is-green" rounded icon-right="tune" class="advanced" @click="onClickAvanzado">
            AVANZADO
          </BButton>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import options from '@/data/next/options';
import { BButton } from 'buefy/dist/esm/button';
import { BIcon } from 'buefy/dist/esm/icon';
import { BSelect } from 'buefy/dist/esm/select';
import {
  btnVolver,
  btnLive,
  clickSelectTipoDeApuesta,
  clickBtnAvanzado,
  clickEstadisticaTipoDeApuestaVerInformacion,
  clickEstadisticaComoFunciona,
} from '@/utils/analytics';

export default {
  name: 'DesktopMenuNextMatches',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BIcon,
    // eslint-disable-next-line vue/no-unused-components
    BSelect,
  },
  props: {
    size: {
      type: Number,
      required: true,
    },
    availableGames: {
      type: Array,
      default: () => [],
    },
    areGamesVisible: {
      type: Boolean,
      default: false,
    },
    isInnerComponent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      analysisTypeOptions: options.analysisTypeOptions,
      selectedGame: {},
    };
  },
  watch: {
    availableGames: {
      deep: true,
      handler(newValue) {
        this.selectedGame = newValue[0];
      },
    },
    selectedGame: {
      deep: true,
      handler(newValue) {
        this.$emit('game-change', newValue);
      },
    },
    gameForAnalysis: {
      deep: true,
      handler(newValue) {
        if (this.availableGames && this.availableGames.length) {
          this.selectedGame = this.availableGames.find(game => game.game_id === newValue.game_id);
        }
      },
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('nextGeneral', ['showLiveData', 'selectedAnalysis', 'gameForAnalysis']),
    ...mapGetters('nextGeneral', ['gameId', 'menuOptionActive', 'showLive']),
    ...mapGetters('general', ['getPreviewSectionAnalytics']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  beforeMount() {
    if (!this.selectedAnalysis.name) {
      this.$store.commit('nextGeneral/updateSelectedAnalysis', this.analysisTypeOptions[0]);
    }
  },
  methods: {
    ...mapMutations('nextGeneral', ['toggleShowLiveData', 'toggleAdvanceFilter', 'updateSelectedAnalysis']),
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('general', ['setVideoModalVisibility', 'setVideoSrc']),
    ...mapMutations(['SET_PREMIUM_MESSAGE', 'setModalInfoVisible']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    toBack() {
      if (this.$route.query.backRoute) {
        this.$router.push({ name: this.$route.query.backRoute });
      } else {
        btnVolver({ game_id: this.gameId, is_premium: this.getPremiumAccount, golstats: this.getIsGolstats });
        let backUrl = null;
        if (this.$route.query.pages === 'calendar-gs-2') {
          backUrl += this.$route.query.pages;
        } else if (this.areGamesVisible) {
          backUrl += '#betTool';
        }
        if (backUrl) {
          this.$router.push(backUrl);
        } else {
          if (history.length > 1) {
            this.$router.back();
          } else {
            this.$router.push('/');
          }
        }
      }
    },
    switchShowLiveData() {
      btnLive(this.game_id, this.showLiveData, this.getPremiumAccount, this.getIsGolstats);

      if (this.getPremiumAccount) this.toggleShowLiveData();
      else this.SET_PREMIUM_MESSAGE(true);
    },
    showVideo() {
      clickEstadisticaComoFunciona(this.getPremiumAccount, this.getPreviewSectionAnalytic, this.getIsGolstats);
      this.setVideoSrc('https://az740894.vo.msecnd.net/golstats-bets/golstats.mp4');
      this.setVideoModalVisibility(true);
    },
    onChangeTipoApuesta(e) {
      let selected = e.target.selectedIndex;
      if (selected >= 0) {
        let selectedText = e.target[selected].text;
        clickSelectTipoDeApuesta(
          this.gameId,
          this.showLive,
          selectedText,
          this.getPremiumAccount,
          this.getPreviewSectionAnalytics,
          this.getIsGolstats,
        );
      }
    },
    onClickAvanzado() {
      clickBtnAvanzado(
        this.gameId,
        this.showLive,
        this.getPremiumAccount,
        this.getPreviewSectionAnalytics,
        this.getIsGolstats,
      );
      this.toggleAdvanceFilter();
    },
    onClickTipoDeApuestaVerInformacion() {
      clickEstadisticaTipoDeApuestaVerInformacion(
        this.getPremiumAccount,
        this.getPreviewSectionAnalytics,
        this.getIsGolstats,
      );
      this.setModalInfoVisible({
        isVisible: true,
        title: 'ESTADÍSTICAS',
        componentName: 'TypeOfBets',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-selector {
  display: grid;
  grid-template-columns: 120px 120px 1fr;

  &__text {
    align-self: center;
    justify-self: right;
  }

  &__select {
    padding-left: 0.5rem;
  }
}
.golstats-button {
  font-size: 1rem !important;
}
.is-inline-flex {
  align-items: center;
  span {
    padding: 0 10px;
  }
}
.no-padding {
  padding: 0px 0px !important;
}
.button-video {
  color: white;
  background-color: #428ee6;
  font-size: 14px;
  height: 36px;
  width: 170px;
}
.div-flag-menu-hidden {
  width: 390px;
  height: 48px;
  background: white;
  @media screen and (max-width: 830px) {
    width: 289px;
  }
}
.margin-column-select {
  margin-top: -40px !important;
}
.advanced {
  background-color: #7ed321 !important;
  color: white !important;
}
.advanced:hover {
  background-color: #8ede3c !important;
  color: #076f07 !important;
}
.is-live-inactive {
  color: white;
  background-color: #d8d8d8;
}
.is-live-inactive:hover {
  background-color: #b4abab;
}
.is-live-active {
  color: white;
  background-color: #282828;
  & > span.icon {
    color: #7ed321;
  }
}
.no-padding {
  padding: 0px 0px !important;
}
.is-live-active:hover {
  color: #87fd03;
  background-color: #171616;
  & > span.icon {
    color: #87fd03;
  }
}
@media only screen and (max-width: 830px) {
  .button-video {
    color: white;
    background-color: #428ee6;
    font-size: 14px;
    height: 36px;
    width: 150px;
    font-family: Circular-Std-Bold, sans-serif;
  }
  .margin-flag-live {
    margin-right: 0.1rem !important;
  }
  .margin-column-select {
    margin-top: -40px !important;
    padding-left: 0px;
    padding-right: 1px;
  }
}
.info-buttons {
  cursor: pointer;
}
</style>
